import React from 'react';

import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import TextLayoutContainer from 'components/TextLayoutContainer/TextLayoutContainer';

const PostalPage = () => (
  <Layout>
    <Seo title="Τρόποι αποστολής" />
    <Container style={{ paddingBottom: '4rem' }}>
      <TextLayoutContainer>
        <h1>Τρόποι αποστολής</h1>
        <h2>Aποστολές σε όλη την Ελλάδα και Κύπρο</h2>
        <p>
          Ο συνεργάτης μας για αποστολές σε όλη την Ελλάδα και Κύπρο είναι η
          ΓΕΝΙΚΗ ΤΑΧΥΔΡΟΜΙΚΗ (ή οποιοσδήποτε άλλος,κατόπιν συνεννόησης με τον
          πελάτη).
          <br />
        </p>
        <h2>Σε πόσες μέρες θα την παραλάβω?</h2>
        <p>
          Για ετοιμοπαράδοτα προϊόντα ο χρόνος αποστολής είναι 1 έως 3 εργάσιμες
          ημέρες. Για τα μη ετοιμοπαράδοτα ο χρόνος αποστολής είναι από 7 ημέρες
          έως 14 ημέρες. Αν ο χρόνος αποστολής είναι πάνω από 14 ημέρες, θα
          υπάρχει συνεννόηση μαζί σας.
        </p>
        <p>
          Ο χρόνος παραλαβής της παραγγελίας σας σε όλη την Ελλάδα είναι από 1
          έως και 3 εργάσιμες ημέρες από την ημέρα αποστολής της.
        </p>
        <p>
          Για τις δυσπρόσιτες περιοχές χρειάζονται 1 – 2 ημέρες επιπλέον.
          <br /> Τα δέματα παραδίδονται από Δευτέρα έως και Παρασκευή 08:00 -
          20:00.
        </p>
        <p>
          Στην περίπτωση που δεν καταστεί δυνατή η παράδοση της παραγγελίας σας,
          ο αποστολέας θα σας αφήσει ένα σημείωμα ή θα σας αποστείλει SMS, για
          τον τρόπο παραλαβής και επικοινωνίας με το υποκατάστημα της ΓΕΝΙΚΗΣ
          ΤΑΧΥΔΡΟΜΙΚΗΣ.
        </p>
        <p>
          <em>
            *Οι χρόνοι αποστολής ενδέχεται να μεταβληθούν λόγω αστάθμητων
            παραγόντων όπως: μη άμεση διαθεσιμότητας του προϊόντος, ειδικής
            επεξεργασίας, απεργίες, καθυστερήσεις μεταφορικών εταιρειών, αργιών,
            εορτών κ.τ.λ.
          </em>
        </p>
        <h2>Μπορώ να εντοπίσω την παραγγελία μου?</h2>
        <p>
          Κάθε πελάτης που έχει δώσει ηλεκτρονική διεύθυνση (e-mail) στα
          στοιχεία του, την ημέρα αποστολής της παραγγελίας του παραλαμβάνει ένα
          μοναδικό αριθμό.
          <br /> Με τον αριθμό αυτό είναι δυνατή η παρακολούθηση της παραγγελίας
          σας στο σύστημα της ΓΕΝΙΚΗΣ ΤΑΧΥΔΡΟΜΙΚΗΣ{' '}
          <a href="http://www.taxydromiki.com/track">
            <strong>
              <span>εδώ.</span>
            </strong>
          </a>
        </p>
      </TextLayoutContainer>
    </Container>
  </Layout>
);

export default PostalPage;
